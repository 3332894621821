<template>
	<div class="content-wrap">
		<!--<h1>项目流程进度报表</h1>-->
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox :isShowBtn="true" @onSearch="handleSearch" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="项目名称">
				<Input
					v-model="searchParams.projectName"
					placeholder="项目名称"
				></Input>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="项目状态">
				<Select
					v-model="searchParams.status"
					clearable
					:transfer="true"
					placeholder="请选择项目状态"
				>
					<Option
						:value="item.obj.nickname"
						v-for="item in projectStep"
						:key="item.obj.nickname"
						>{{ item.name }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				v-if="systemActionPermissions.indexOf('btn_process_export') > -1"
				@click="exportData"
				>导出
			</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
			ref="table"
		>
			<template slot-scope="{ row }" slot="projectName">
				<a v-if="row.isHasProject" @click="toProject(row)" href="javascript:">{{
					row.projectName
				}}</a>
				<span v-else>{{ row.projectName }}</span>
			</template>
			<template slot-scope="{ row }" slot="recentlyProcessName">
				<a
					v-if="(row.isCanApproval || row.isCanEdit) && row.recentlyProcessName"
					@click="toApproval(row)"
					href="javascript:"
					>{{ row.recentlyProcessName }}</a
				>
				<span v-else>{{ row.recentlyProcessName || "-" }}</span>
			</template>
		</CtmsDataGrid>
		<!-- 流程审批-->
		<Approval
			:visible="approvalVisible"
			:projectId="projectId"
			@onOk="handleOk"
			@onCancel="handleCancel"
			:procInstId="procInstId"
			:procInstName="procInstName"
			:taskId="taskId"
			:type="isCanEdit ? 'toDo' : 'view'"
			:toDoId="toDoId"
		></Approval>
	</div>
</template>

<script>
import api from "@/api/report/report"
import Approval from "@approval"
import { mapState } from "vuex"
import publicApi from "@/api/public"

const { apiGetProjectProgress } = api
const { publicGetDictionaryList } = publicApi

export default {
	name: "ProjectSchedule",
	components: {
		Approval
	},
	data() {
		return {
			loading: false,
			approvalVisible: false,
			toDoId: "",
			procInstId: "",
			procInstName: "",
			projectId: "",
			taskId: "",
			isCanEdit: false, // 是否有审批权限
			projectStep: [],
			listData: [],
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 200,
					slot: "projectName"
				},
				{
					title: "项目状态",
					key: "statusText",
					minWidth: 120
				},
				{
					title: "已启动流程数",
					key: "startProcessCnt",
					minWidth: 120
				},
				{
					title: "已完成流程数",
					key: "hasDoneProcessCnt",
					minWidth: 120
				},
				{
					title: "进行中流程数",
					key: "ingProcessCnt",
					minWidth: 120
				},
				{
					title: "最近进行中的流程",
					key: "recentlyProcessName",
					minWidth: 140,
					slot: "recentlyProcessName"
				}
			],
			searchParams: {
				status: "",
				projectName: ""
			},
			oldSearchParams: {},
			// 分页
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 140
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	created() {
		this.getProjectStepInfo()
		this.initList("init")
	},
	methods: {
		// 项目阶段
		getProjectStepInfo() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await publicGetDictionaryList({ type: 25, isDel: 0 })
				if (res) {
					const arr = res.data && res.data.length ? res.data[0].leaf : []
					const info = arr.map(item => ({
						...item,
						obj: {
							...item.obj,
							nickname: Number(item.obj.nickname)
						}
					}))
					this.projectStep = info
				}
				this.loading = false
			})
		},
		initList(type) {
			this.$asyncDo(async () => {
				this.loading = true
				if (type === "init") {
					const paramsStr = sessionStorage.getItem("reportParams")
					if (paramsStr) {
						const { params, page } = JSON.parse(paramsStr)
						this.oldSearchParams = { ...params }
						this.searchParams = {
							...this.searchParams,
							...params
						}
						this.page.current = page.current
						this.page.pageSize = page.pageSize
					}
					sessionStorage.setItem("reportParams", "")
				}
				const { status, projectName } = this.oldSearchParams
				const statusCode = status || status === 0 ? status : ""
				const res = await apiGetProjectProgress({
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					status: statusCode,
					projectName: projectName || ""
				})
				if (res) {
					this.listData = res.data ? res.data.list : []
					this.page.total = res.data ? res.data.total : 0
				}
				this.loading = false
			})
		},
		// 跳转到项目工作台
		toProject(row) {
			sessionStorage.setItem(
				"reportParams",
				JSON.stringify({
					params: {
						...this.oldSearchParams
					},
					page: {
						current: this.page.current,
						pageSize: this.page.pageSize
					}
				})
			)
			window.history.pushState(
				{
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				},
				null,
				window.location.href
			)
			this.$router.push({
				name: "projectWorkbench",
				params: {
					projectId: row.id
				}
			})
		},
		// 弹出流程审批
		toApproval(row) {
			this.toDoId = row.recentlyProcessVO.latestTodoId
			this.procInstId = row.recentlyProcessVO.id
			this.procInstName = row.recentlyProcessVO.name
			this.projectId = row.id
			this.taskId = row.recentlyProcessVO.taskId
			this.isCanEdit = row.isCanEdit
			this.$nextTick(() => {
				this.approvalVisible = true
			})
		},
		// 审批提交成功
		handleOk() {
			this.approvalVisible = false
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 审批关闭
		handleCancel() {
			this.procInstId = ""
			this.procInstName = ""
			this.toDoId = ""
			this.approvalVisible = false
		},
		// 导出
		exportData() {
			const { status, projectName } = this.oldSearchParams
			const statusCode = status || status === 0 ? status : ""
			window.open(
				`${this.$baseUrl}/report/export-process-excel?status=${statusCode}&projectName=${projectName || ""}&token=${this.$store.state.user.token}`
			)
		},
		// 重置
		reset() {
			this.searchParams = {
				status: "",
				projectName: ""
			}
			this.handleSearch()
		},
		// 搜索
		handleSearch() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页码改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>
